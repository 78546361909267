import { ADD_ONS_TAB_SECTIONS } from "@app/analysis/addOns/state/addOns.constants";
import { TIME_PERIODS_TAB_SECTIONS } from "@app/analysis/timePeriods/state/timePeriods.constants";
import { OSM_LAYERS } from "@common/components/baseMap/mapLayers/mapLayers.constants";
import {
    ANALYSIS_TYPES,
    MODES_OF_TRAVEL,
    ORG_COUNTRIES,
} from "@common/constants/analysis.constants";
import NPSegmentIcon from "@root/src/assets/img/np-segment-metric.svg";
import NPSpotIcon from "@root/src/assets/img/np-spot-metric.svg";
import TMCHistoricDataIcon from "@root/src/assets/img/tmc-historic-data.svg";
import TMCRecentDataIcon from "@root/src/assets/img/tmc-recent-data.svg";

export const CALIBRATION_CODE_VOLUME = "multi_factor_stl_vol_calibration";

export const AADT_CALIBRATIONS = {
    AADT_2016: {
        id: 595,
        code: "aadt_calibration_2016",
    },
    AADT_2017: {
        id: 594,
        code: "aadt_calibration_2017",
    },
    AADT_2018: {
        id: 593,
        code: "aadt_calibration_2018",
    },
    AADT_2019: {
        id: 598,
        code: "aadt_calibration_2019",
    },
    AADT_2020: {
        id: 599,
        code: "aadt_calibration_2020",
    },
    AADT_2021: {
        id: 604,
        code: "aadt_calibration_2021",
    },
    AADT_2022: {
        id: 605,
        code: "aadt_calibration_2022",
    },
};

export const CALIBRATIONS = {
    VOLUME: {
        id: 596,
        code: CALIBRATION_CODE_VOLUME,
        display: "StreetLight All Vehicles Volume",
        measurementLabel: "Volume",
        featureName: "stl_volume",
    },
    BIKE_VOLUME: {
        id: 600,
        code: CALIBRATION_CODE_VOLUME,
        display: "StreetLight Bicycle Volume",
        measurementLabel: "Volume",
        featureName: "personal_bike_travel",
    },
    PED_VOLUME: {
        id: 601,
        code: CALIBRATION_CODE_VOLUME,
        display: "StreetLight Pedestrian Volume",
        measurementLabel: "Volume",
        featureName: "personal_ped_travel",
    },
    TRUCK_VOLUME: {
        id: 603,
        code: CALIBRATION_CODE_VOLUME,
        display: "StreetLight Truck Volume",
        measurementLabel: "Volume",
        featureName: "stl_truck_volume",
    },
    INDEX: {
        id: 590,
        code: "no_calibration",
        display: "StreetLight Index",
        measurementLabel: "Index",
        featureName: "stl_idx",
    },
    TRIP_COUNTS: {
        id: 597,
        code: "trip_counts",
        display: "StreetLight Sample Trip Counts",
        measurementLabel: "Trip Counts",
        featureName: "sample_trip_count",
    },
    AADT: {
        id: 999, //dummy id, actual search should be performed on aadtCalibrations
        code: "aadt_calibration", // AADT has specific logic to check if one AADT year is available.
        display: "Single Factor Calibrated Index using StreetLight AADT",
        measurementLabel: "Index",
        aadtCalibrations: Object.values(AADT_CALIBRATIONS),
    },
    USER_COUNTS: {
        id: 592,
        code: "calibration_zone_counts",
        display: "Single Factor Calibrated Index using user counts",
        measurementLabel: "Index",
        featureName: "calibration",
    },
    IPF: {
        id: 591,
        code: "calibration_ipf",
        display: "Calibrated Index with IPF using user counts",
        measurementLabel: "Index",
        featureName: "ipf_calibration",
    },
    // Used only for AADT analyses
    AADT_OUTPUT: {
        id: 602,
        code: "aadt_output",
        display: "StreetLight AADT (Vehicle Trips)",
        measurementLabel: "AADT",
        featureName: "stl_volume",
        shouldIgnoreSuffix: true, //don't allow adding custom suffix based on selected travel mode
    },
};
export type TAnalysisCalibration = typeof CALIBRATIONS[keyof typeof CALIBRATIONS];

export const CALIBRATIONS_BY_CODE = {
    [CALIBRATIONS.VOLUME.code]: CALIBRATIONS.VOLUME,
    [CALIBRATIONS.INDEX.code]: CALIBRATIONS.INDEX,
    [CALIBRATIONS.TRIP_COUNTS.code]: CALIBRATIONS.TRIP_COUNTS,
    [CALIBRATIONS.AADT.code]: CALIBRATIONS.AADT,
    [CALIBRATIONS.USER_COUNTS.code]: CALIBRATIONS.USER_COUNTS,
    // Available only for OD analysis for All Vehicles/Trucks travel mode
    [CALIBRATIONS.IPF.code]: CALIBRATIONS.IPF,
    // Available only for AADT analyses
    [CALIBRATIONS.AADT_OUTPUT.code]: CALIBRATIONS.AADT_OUTPUT,
};
export const CALIBRATIONS_LIST = Object.values(CALIBRATIONS);
export type TCalibration = typeof CALIBRATIONS[keyof typeof CALIBRATIONS];

export const OSM_NETWORK_TYPES = {
    VEHICLE: {
        id: "vehicle",
        name: "Vehicle Network",
    },
    BICYCLE: {
        id: "bicycle",
        name: "Bike Network",
    },
    PEDESTRIAN: {
        id: "pedestrian",
        name: "Ped Network",
    },
} as const;

export type TOsmNetworkType = typeof OSM_NETWORK_TYPES[keyof typeof OSM_NETWORK_TYPES];

type TGetIsAvailableProps = {
    analysisTypeCode: string;
    country: string;
    aadtYear: number;
};

export const TRAVEL_MODES = {
    ALL_VEHICLES: {
        code: MODES_OF_TRAVEL.ALL_VEHICLES.code,
        display: "All Vehicles LBS+",
        editableDataDateRanges: true,
        shouldShowPresetPeriodsOptions: true,
        featureName: "personal_all_vehicle_travel",
        featureValidationText: "Travel Type Personal - All Vehicles for analyses is disabled.",
        options: [ADD_ONS_TAB_SECTIONS.HOME_AND_WORK_LOCATIONS.code],
        calibration: {
            modes: [
                CALIBRATIONS.VOLUME,
                CALIBRATIONS.INDEX,
                CALIBRATIONS.TRIP_COUNTS,
                CALIBRATIONS.USER_COUNTS,
            ],
            displaySuffixes: {
                default: "Vehicle Trips",
                [CALIBRATIONS.INDEX.code]: "Device Trips",
                [CALIBRATIONS.TRIP_COUNTS.code]: "Device Trips",
            },
        },
        osmLayer: OSM_LAYERS.OSM_VEHICLE,
        osmNetwork: OSM_NETWORK_TYPES.VEHICLE,
        isBusZonesSupported: true,
        getIsAvailable: () => true,
    },
    // Used only for US AADT analyses
    ALL_VEHICLES_BY_WEIGHT: {
        code: MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code,
        display: "All Vehicles (By Weight)",
        editableDataDateRanges: true,
        shouldShowPresetPeriodsOptions: true,
        featureName: "estimated_aadt_by_weight",
        featureValidationText: "AADT By Weight Class is disabled.",
        options: [],
        calibration: {
            modes: [CALIBRATIONS.AADT_OUTPUT],
            displaySuffixes: {
                default: "Vehicle Trips",
            },
        },
        osmLayer: OSM_LAYERS.OSM_VEHICLE,
        osmNetwork: OSM_NETWORK_TYPES.VEHICLE,
        isBusZonesSupported: false,
        getIsAvailable: ({ analysisTypeCode, country }: TGetIsAvailableProps) => {
            return (
                analysisTypeCode === CREATE_ANALYSIS_TYPES.AADT.code &&
                country === ORG_COUNTRIES.US.code
            );
        },
    },
    TRUCK: {
        code: MODES_OF_TRAVEL.TRUCK.code,
        display: "Truck",
        editableDataDateRanges: true,
        shouldShowPresetPeriodsOptions: true,
        featureName: "commercial_truck_travel",
        featureValidationText: "Travel Type Commercial - Truck for analyses is disabled.",
        options: [ADD_ONS_TAB_SECTIONS.COMMERCIAL_VEHICLE_FILTERS.code],
        calibration: {
            modes: [
                CALIBRATIONS.TRUCK_VOLUME,
                CALIBRATIONS.INDEX,
                CALIBRATIONS.TRIP_COUNTS,
                CALIBRATIONS.USER_COUNTS,
            ],
            displaySuffixes: { default: "Truck Trips" },
        },
        osmLayer: OSM_LAYERS.OSM_VEHICLE,
        osmNetwork: OSM_NETWORK_TYPES.VEHICLE,
        isBusZonesSupported: false,
        getIsAvailable: () => true,
    },
    BICYCLE: {
        code: MODES_OF_TRAVEL.BICYCLE.code,
        display: "Bicycle",
        editableDataDateRanges: false,
        shouldShowPresetPeriodsOptions: true,
        featureName: "personal_bike_travel",
        featureValidationText: "Travel Type Personal - Bike for analyses is disabled.",
        options: [ADD_ONS_TAB_SECTIONS.HOME_AND_WORK_LOCATIONS.code], //Todo: Complete the options list
        calibration: {
            modes: [CALIBRATIONS.BIKE_VOLUME, CALIBRATIONS.USER_COUNTS, CALIBRATIONS.TRIP_COUNTS],
            displaySuffixes: { default: "Bicycle Trips" },
        },
        osmLayer: OSM_LAYERS.OSM_BICYCLE,
        osmNetwork: OSM_NETWORK_TYPES.BICYCLE,
        isBusZonesSupported: true,
        getIsAvailable: () => true,
    },
    PEDESTRIAN: {
        code: MODES_OF_TRAVEL.PEDESTRIAN.code,
        display: "Pedestrian",
        editableDataDateRanges: false,
        shouldShowPresetPeriodsOptions: true,
        featureName: "personal_ped_travel",
        featureValidationText: "Travel Type Personal - Pedestrian for analyses is disabled.",
        options: [ADD_ONS_TAB_SECTIONS.HOME_AND_WORK_LOCATIONS.code], //Todo: Complete the options list
        calibration: {
            modes: [CALIBRATIONS.PED_VOLUME, CALIBRATIONS.USER_COUNTS, CALIBRATIONS.TRIP_COUNTS],
            displaySuffixes: { default: "Pedestrian Trips" },
        },
        osmLayer: OSM_LAYERS.OSM_PEDESTRIAN,
        osmNetwork: OSM_NETWORK_TYPES.PEDESTRIAN,
        isBusZonesSupported: true,
        getIsAvailable: () => true,
    },
    BUS: {
        code: MODES_OF_TRAVEL.BUS.code,
        display: "Bus",
        editableDataDateRanges: false,
        shouldShowPresetPeriodsOptions: true,
        featureName: "personal_bus_travel",
        featureValidationText: "Travel Type Personal - Bus for analyses is disabled.",
        options: [ADD_ONS_TAB_SECTIONS.HOME_AND_WORK_LOCATIONS.code], //Todo: Complete the options list
        calibration: {
            modes: [CALIBRATIONS.INDEX],
            displaySuffixes: {
                default: "Bus Trips",
                [CALIBRATIONS.INDEX.code]: {
                    [ORG_COUNTRIES.US.code]: "Bus Passenger Trips",
                    [ORG_COUNTRIES.CA.code]: "Bus Trips",
                },
            },
        },
        osmLayer: OSM_LAYERS.OSM_VEHICLE,
        osmNetwork: OSM_NETWORK_TYPES.VEHICLE,
        isBusZonesSupported: true,
        getIsAvailable: () => true,
    },
    RAIL: {
        code: MODES_OF_TRAVEL.RAIL.code,
        display: "Rail",
        editableDataDateRanges: false,
        shouldShowPresetPeriodsOptions: true,
        featureName: "personal_rail_travel",
        featureValidationText: "Travel Type Personal - Rail for analyses is disabled.",
        options: [ADD_ONS_TAB_SECTIONS.HOME_AND_WORK_LOCATIONS.code], //Todo: Complete the options list
        calibration: {
            modes: [CALIBRATIONS.INDEX],
            displaySuffixes: {
                default: "Rail Trips",
                [CALIBRATIONS.INDEX.code]: {
                    [ORG_COUNTRIES.US.code]: "Rail Passenger Trips",
                    [ORG_COUNTRIES.CA.code]: "Rail Trips",
                },
            },
        },
        osmLayer: OSM_LAYERS.OSM_VEHICLE,
        osmNetwork: OSM_NETWORK_TYPES.VEHICLE,
        isBusZonesSupported: false,
        getIsAvailable: () => true,
    },
} as const;

export const TRAVEL_MODES_LIST = Object.values(TRAVEL_MODES);

export type TTravelMode = typeof TRAVEL_MODES_LIST[number];

export const ALL_VEHICLES_TRAVEL_MODES = {
    ALL_VEHICLES_ONLY: {
        ...TRAVEL_MODES.ALL_VEHICLES,
        code: MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code,
        display: "All Vehicles",
    },
    ALL_VEHICLES_LBS: {
        ...TRAVEL_MODES.ALL_VEHICLES,
    },
    ALL_VEHICLES_CVD: {
        ...TRAVEL_MODES.ALL_VEHICLES,
        code: MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code,
        display: "All Vehicles CVD+",
        options: [],
        calibration: {
            modes: [CALIBRATIONS.VOLUME, CALIBRATIONS.TRIP_COUNTS],
            displaySuffixes: {
                default: "Vehicle Trips",
                [CALIBRATIONS.INDEX.code]: "Device Trips",
                [CALIBRATIONS.TRIP_COUNTS.code]: "Device Trips",
            },
        },
    },
    // Used only for TT Segment Analyses
    ALL_VEHICLES_TOMTOM: {
        ...TRAVEL_MODES.ALL_VEHICLES,
        code: MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code,
        display: "All Vehicles",
        featureName: "personal_all_vehicle_travel",
        featureValidationText: "All Vehicles is disabled.",
        options: [],
        calibration: {
            modes: [CALIBRATIONS.VOLUME, CALIBRATIONS.TRIP_COUNTS],
            displaySuffixes: {
                default: "Vehicle Trips",
                [CALIBRATIONS.TRIP_COUNTS.code]: "Device Trips",
            },
        },
        isBusZonesSupported: false,
        getIsAvailable: ({ analysisTypeCode }: TGetIsAvailableProps) => {
            return [
                CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.code,
                CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.code,
            ].includes(analysisTypeCode);
        },
    },
    ALL_VEHICLES_TOMTOM_API: {
        ...TRAVEL_MODES.ALL_VEHICLES,
        code: MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code,
        display: "All Vehicles",
        featureValidationText: "All Vehicles is disabled.",
        options: [],
        calibration: {
            modes: [CALIBRATIONS.VOLUME, CALIBRATIONS.TRIP_COUNTS],
            displaySuffixes: {
                default: "Vehicle Trips",
                [CALIBRATIONS.TRIP_COUNTS.code]: "Device Trips",
            },
        },
        isBusZonesSupported: false,
        getIsAvailable: ({ analysisTypeCode }: TGetIsAvailableProps) => {
            return analysisTypeCode === CREATE_ANALYSIS_TYPES.NETWORK_OD.code;
        },
    },
};

export const ALL_VEHICLES_TRAVEL_MODES_LIST = Object.values(ALL_VEHICLES_TRAVEL_MODES);

export const US_ONLY_TRAVEL_MODES = [
    MODES_OF_TRAVEL.ALL_VEHICLES.code,
    MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code,
    MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code,
    MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code,
];

export const PRESET_GEOG_TYPES = {
    TAZ: {
        code: "taz",
        display: "2010 US Census Traffic Analysis Zones",
        country: "US",
        featureName: "country_us",
        isComputedLabel: false,
    },
    BLKGRP: {
        code: "blkgrp",
        display: "US Census Block Groups",
        country: "US",
        featureName: "country_us",
        isComputedLabel: true, //indicates that label should be computed based on US Census type (2010|2020)
    },
    ZIP: {
        code: "zip",
        display: "US Census Zip Codes",
        country: "US",
        featureName: "country_us",
        isComputedLabel: true,
    },
    DA: {
        code: "da",
        display: "2016 Canadian Census Dissemination Areas",
        country: "CA",
        featureName: "country_ca",
        isComputedLabel: false,
    },
};

export const PRESET_GEOG_TYPES_LIST = Object.values(PRESET_GEOG_TYPES);
export const DEFAULT_PRESET_GEOG_TYPE = PRESET_GEOG_TYPES.BLKGRP;
export type TPresetGeogType = typeof PRESET_GEOG_TYPES[keyof typeof PRESET_GEOG_TYPES];

export const ROUTE_OPTIONS = {
    Motorway: true,
    Trunk: true,
    Primary: true,
    Secondary: true,
    Tertiary: true,
    Residential: false,
};

export type TRouteOptions = typeof ROUTE_OPTIONS;

export const COMMERCIAL_TYPES = {
    LIGHT_TRUCKS: {
        vehicleWeightId: 210,
        code: "Light",
        display: "Light Duty",
    },
    MEDIUM_TRUCKS: {
        vehicleWeightId: 211,
        code: "Medium",
        display: "Medium Duty",
    },
    HEAVY_TRUCKS: {
        vehicleWeightId: 212,
        code: "Heavy",
        display: "Heavy Duty",
    },
};
export const COMMERCIAL_TYPES_LIST = Object.values(COMMERCIAL_TYPES);

export const CREATE_ANALYSIS_TYPES = {
    OD: {
        code: ANALYSIS_TYPES.OD.id,
        display: ANALYSIS_TYPES.OD.name,
        urlPath: ANALYSIS_TYPES.OD.id,
        img: "/img/zone-types/od.svg",
        featureName: "od_analysis",
        gmCVDFeatureName: "gm_od",
        isLightning: false,
        travelModes: [
            TRAVEL_MODES.ALL_VEHICLES,
            TRAVEL_MODES.TRUCK,
            TRAVEL_MODES.BICYCLE,
            TRAVEL_MODES.PEDESTRIAN,
            TRAVEL_MODES.BUS,
            TRAVEL_MODES.RAIL,
        ],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_MONTHS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [
            ADD_ONS_TAB_SECTIONS.SPEED_PERCENTILE,
            ADD_ONS_TAB_SECTIONS.TRAVEL_TIME_PERCENTILE,
            ADD_ONS_TAB_SECTIONS.COMMERCIAL_VEHICLE_FILTERS,
            ADD_ONS_TAB_SECTIONS.TRIP_ATTRIBUTES,
            ADD_ONS_TAB_SECTIONS.TRAVELER_ATTRIBUTES,
            ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS,
            ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS,
        ],
        calibrations: [
            CALIBRATIONS.VOLUME,
            CALIBRATIONS.BIKE_VOLUME,
            CALIBRATIONS.PED_VOLUME,
            CALIBRATIONS.INDEX,
            CALIBRATIONS.TRIP_COUNTS,
            CALIBRATIONS.AADT,
            CALIBRATIONS.USER_COUNTS,
            CALIBRATIONS.IPF,
        ],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: true,
        supportsNewDayTypesAndParts: true,
    },
    ODMF: {
        code: ANALYSIS_TYPES.ODMF.id,
        display: ANALYSIS_TYPES.ODMF.name,
        urlPath: ANALYSIS_TYPES.ODMF.id,
        img: "/img/zone-types/od-middle-filters.svg",
        featureName: "odmf_analysis",
        gmCVDFeatureName: "gm_odmf",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES, TRAVEL_MODES.TRUCK, TRAVEL_MODES.BICYCLE],
        deprecatedTravelModes: [TRAVEL_MODES.BUS, TRAVEL_MODES.RAIL],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_MONTHS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [
            ADD_ONS_TAB_SECTIONS.SPEED_PERCENTILE,
            ADD_ONS_TAB_SECTIONS.TRAVEL_TIME_PERCENTILE,
            ADD_ONS_TAB_SECTIONS.COMMERCIAL_VEHICLE_FILTERS,
            ADD_ONS_TAB_SECTIONS.TRIP_ATTRIBUTES,
            ADD_ONS_TAB_SECTIONS.TRAVELER_ATTRIBUTES,
            ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS,
            ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS,
        ],
        calibrations: [
            CALIBRATIONS.VOLUME,
            CALIBRATIONS.BIKE_VOLUME,
            CALIBRATIONS.PED_VOLUME,
            CALIBRATIONS.INDEX,
            CALIBRATIONS.TRIP_COUNTS,
            CALIBRATIONS.AADT,
            CALIBRATIONS.USER_COUNTS,
            CALIBRATIONS.IPF,
        ],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: true,
        supportsNewDayTypesAndParts: true,
    },
    ZA: {
        code: ANALYSIS_TYPES.ZA.id,
        display: ANALYSIS_TYPES.ZA.name,
        urlPath: ANALYSIS_TYPES.ZA.id,
        img: "/img/zone-types/za.svg",
        featureName: "za_analysis",
        gmCVDFeatureName: "gm_za",
        isLightning: false,
        travelModes: [
            TRAVEL_MODES.ALL_VEHICLES,
            TRAVEL_MODES.TRUCK,
            TRAVEL_MODES.BICYCLE,
            TRAVEL_MODES.PEDESTRIAN,
            TRAVEL_MODES.BUS,
            TRAVEL_MODES.RAIL,
        ],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_MONTHS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [
            ADD_ONS_TAB_SECTIONS.COMMERCIAL_VEHICLE_FILTERS,
            ADD_ONS_TAB_SECTIONS.TRIP_ATTRIBUTES,
            ADD_ONS_TAB_SECTIONS.TRAVELER_ATTRIBUTES,
            ADD_ONS_TAB_SECTIONS.HOME_AND_WORK_LOCATIONS,
            ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS,
            ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS,
        ],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: true,
        supportsNewDayTypesAndParts: true,
    },
    ODG: {
        code: ANALYSIS_TYPES.ODG.id,
        display: ANALYSIS_TYPES.ODG.name,
        urlPath: ANALYSIS_TYPES.ODG.id,
        img: "/img/zone-types/od-geography.svg",
        gmCVDFeatureName: "gm_odg",
        featureName: "od_preset_geographies",
        isLightning: false,
        travelModes: [
            TRAVEL_MODES.ALL_VEHICLES,
            TRAVEL_MODES.TRUCK,
            TRAVEL_MODES.BICYCLE,
            TRAVEL_MODES.PEDESTRIAN,
        ],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_MONTHS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [
            ADD_ONS_TAB_SECTIONS.GEOGRAPHIES,
            ADD_ONS_TAB_SECTIONS.COMMERCIAL_VEHICLE_FILTERS,
            ADD_ONS_TAB_SECTIONS.TRIP_ATTRIBUTES,
            ADD_ONS_TAB_SECTIONS.TRAVELER_ATTRIBUTES,
            ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS,
            ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS,
        ],
        calibrations: [
            CALIBRATIONS.VOLUME,
            CALIBRATIONS.BIKE_VOLUME,
            CALIBRATIONS.PED_VOLUME,
            CALIBRATIONS.INDEX,
            CALIBRATIONS.TRIP_COUNTS,
            CALIBRATIONS.AADT,
            CALIBRATIONS.USER_COUNTS,
            CALIBRATIONS.IPF,
        ],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: true,
        supportsNewDayTypesAndParts: true,
    },
    TOP_ROUTES_OD: {
        code: ANALYSIS_TYPES.TROD.id,
        display: ANALYSIS_TYPES.TROD.name,
        urlPath: "Top_Routes_OD",
        gmCVDFeatureName: "gm_trod",
        img: "/img/zone-types/top-routes-od.svg",
        featureName: "top_routes_od",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES, TRAVEL_MODES.TRUCK],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_MONTHS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [
            ADD_ONS_TAB_SECTIONS.TOP_ROUTES,
            ADD_ONS_TAB_SECTIONS.COMMERCIAL_VEHICLE_FILTERS,
            ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS,
            ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS,
        ],
        calibrations: [CALIBRATIONS.VOLUME, CALIBRATIONS.INDEX, CALIBRATIONS.TRIP_COUNTS],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: true,
        supportsNewDayTypesAndParts: true,
    },
    TOP_ROUTES_ZA: {
        code: ANALYSIS_TYPES.TRZA.id,
        display: ANALYSIS_TYPES.TRZA.name,
        urlPath: "Top_Routes_Zones",
        gmCVDFeatureName: "gm_tr",
        img: "/img/zone-types/top-routes-za.svg",
        featureName: "top_routes_za",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES, TRAVEL_MODES.TRUCK],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_MONTHS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [
            ADD_ONS_TAB_SECTIONS.TOP_ROUTES,
            ADD_ONS_TAB_SECTIONS.COMMERCIAL_VEHICLE_FILTERS,
            ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS,
            ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS,
        ],
        calibrations: [CALIBRATIONS.VOLUME, CALIBRATIONS.INDEX, CALIBRATIONS.TRIP_COUNTS],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: true,
        supportsNewDayTypesAndParts: true,
    },
    SEGMENT: {
        code: ANALYSIS_TYPES.SEGMENT.id,
        display: ANALYSIS_TYPES.SEGMENT.name,
        urlPath: ANALYSIS_TYPES.SEGMENT.id,
        img: "/img/zone-types/segment-analysis.svg",
        featureName: "segment_analysis",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES, TRAVEL_MODES.TRUCK],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_PERIODS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [
            ADD_ONS_TAB_SECTIONS.SEGMENT_DURATION,
            ADD_ONS_TAB_SECTIONS.SEGMENT_SPEED,
            ADD_ONS_TAB_SECTIONS.TRAVEL_TIME_PERCENTILE,
            ADD_ONS_TAB_SECTIONS.SPEED_PERCENTILE,
            ADD_ONS_TAB_SECTIONS.COMMERCIAL_VEHICLE_FILTERS,
            ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS,
            ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS,
        ],
        calibrations: [
            CALIBRATIONS.VOLUME,
            CALIBRATIONS.TRUCK_VOLUME,
            CALIBRATIONS.INDEX,
            CALIBRATIONS.TRIP_COUNTS,
            CALIBRATIONS.USER_COUNTS,
        ],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: false,
        supportsNewDayTypesAndParts: false,
    },
    NETWORK_PERFORMANCE_SEGMENT: {
        code: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.id,
        display: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.name,
        urlPath: "Network_Performance",
        img: "/img/zone-types/segment-analysis.svg",
        featureName: "tt_travel",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_MONTHS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [
            ADD_ONS_TAB_SECTIONS.AVERAGE_TRAVEL_TIME,
            ADD_ONS_TAB_SECTIONS.AVERAGE_SPEED,
            ADD_ONS_TAB_SECTIONS.SPEED_PERCENTILE,
            ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS,
            ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS,
        ],
        calibrations: [
            CALIBRATIONS.VOLUME,
            CALIBRATIONS.TRUCK_VOLUME,
            CALIBRATIONS.INDEX,
            CALIBRATIONS.TRIP_COUNTS,
            CALIBRATIONS.USER_COUNTS,
        ],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: true,
        supportsNewDayTypesAndParts: true,
    },
    NETWORK_PERFORMANCE_SPOT: {
        code: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.id,
        display: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.name,
        urlPath: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.id,
        img: "/img/zone-types/segment-analysis.svg",
        featureName: "tt_travel",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_MONTHS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [ADD_ONS_TAB_SECTIONS.SPOT_SPEED, ADD_ONS_TAB_SECTIONS.SPEED_PERCENTILE],
        calibrations: [
            CALIBRATIONS.VOLUME,
            CALIBRATIONS.TRUCK_VOLUME,
            CALIBRATIONS.INDEX,
            CALIBRATIONS.TRIP_COUNTS,
            CALIBRATIONS.USER_COUNTS,
        ],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: true,
        supportsNewDayTypesAndParts: true,
    },
    NETWORK_OD: {
        code: ANALYSIS_TYPES.NETWORK_OD.id,
        display: ANALYSIS_TYPES.NETWORK_OD.name,
        urlPath: ANALYSIS_TYPES.NETWORK_OD.id,
        img: "/img/zone-types/od.svg",
        featureName: "network_od",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.MONTH_RANGES,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [
            ADD_ONS_TAB_SECTIONS.TRIP_ATTRIBUTES,
            ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS,
            ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS,
        ],
        calibrations: [CALIBRATIONS.VOLUME, CALIBRATIONS.TRIP_COUNTS],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: true,
        supportsNewDayTypesAndParts: true,
    },
    CONGESTION: {
        code: ANALYSIS_TYPES.TD.id,
        display: ANALYSIS_TYPES.TD.name,
        urlPath: ANALYSIS_TYPES.TD.id,
        img: "/img/zone-types/traffic-diagnostics.svg",
        featureName: "congestion_analysis",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_PERIODS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [
            ADD_ONS_TAB_SECTIONS.TRIP_ATTRIBUTES,
            ADD_ONS_TAB_SECTIONS.TRAVELER_ATTRIBUTES,
            ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS,
            ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS,
        ],
        calibrations: [CALIBRATIONS.INDEX],
        canChooseTravelMode: false,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: false,
        supportsCustomRanges: false,
        supportsNewDayTypesAndParts: false,
    },
    K_FACTOR_ESTIMATION: {
        code: ANALYSIS_TYPES.K_FACTOR.id,
        display: ANALYSIS_TYPES.K_FACTOR.name,
        urlPath: ANALYSIS_TYPES.K_FACTOR.id,
        img: "/img/zone-types/aadt.svg",
        featureName: "seasonal_k_factor",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_PERIODS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS, ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS],
        calibrations: [CALIBRATIONS.VOLUME],
        canChooseTravelMode: false,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: false,
        supportsCustomRanges: false,
        supportsNewDayTypesAndParts: false,
    },
    TMC: {
        code: ANALYSIS_TYPES.TMC.id,
        display: ANALYSIS_TYPES.TMC.name,
        urlPath: ANALYSIS_TYPES.TMC.id,
        img: "/img/zone-types/tmc.svg",
        featureName: "tmc_historic",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.DATA_PERIODS,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS, ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS],
        calibrations: [CALIBRATIONS.INDEX, CALIBRATIONS.VOLUME],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: false,
        supportsNewDayTypesAndParts: false,
    },
    TT_TMC: {
        code: ANALYSIS_TYPES.TT_TMC.id,
        display: ANALYSIS_TYPES.TT_TMC.name,
        urlPath: "TMC_Recent",
        img: "/img/zone-types/tmc.svg",
        featureName: "tmc_recent",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES],
        timePeriods: [
            TIME_PERIODS_TAB_SECTIONS.MONTH_RANGES,
            TIME_PERIODS_TAB_SECTIONS.DAY_TYPES,
            TIME_PERIODS_TAB_SECTIONS.DAY_PARTS,
        ],
        addOns: [ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS, ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS],
        calibrations: [CALIBRATIONS.VOLUME, CALIBRATIONS.TRIP_COUNTS],
        canChooseTravelMode: true,
        canChooseOutputType: true,
        requiresDataPeriods: true,
        showDownloadWithCount: true,
        supportsCustomRanges: true,
        supportsNewDayTypesAndParts: true,
    },
    CT: {
        code: ANALYSIS_TYPES.CT.id,
        display: ANALYSIS_TYPES.CT.name,
        urlPath: "Congestion_Management",
        img: "/img/zone-types/ct.svg",
        featureName: "congestion_trends",
        isLightning: true,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES],
        timePeriods: [],
        addOns: [],
        calibrations: [],
        canChooseTravelMode: false,
        canChooseOutputType: true,
        requiresDataPeriods: false,
        showDownloadWithCount: false,
        supportsCustomRanges: false,
        supportsNewDayTypesAndParts: false,
    },
    RV: {
        code: ANALYSIS_TYPES.RV.id,
        display: ANALYSIS_TYPES.RV.name,
        urlPath: ANALYSIS_TYPES.RV.id,
        img: "/img/zone-types/rv.svg",
        featureName: "roadway_volume",
        isLightning: true,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES],
        timePeriods: [],
        addOns: [],
        calibrations: [],
        canChooseTravelMode: false,
        canChooseOutputType: true,
        requiresDataPeriods: false,
        showDownloadWithCount: false,
        supportsCustomRanges: false,
        supportsNewDayTypesAndParts: false,
    },
    ATM: {
        code: ANALYSIS_TYPES.ATM.id,
        display: ANALYSIS_TYPES.ATM.name,
        urlPath: ANALYSIS_TYPES.ATM.id,
        img: "/img/zone-types/at.svg",
        featureName: "active_transportation_monitor",
        isLightning: true,
        travelModes: [TRAVEL_MODES.BICYCLE],
        timePeriods: [],
        addOns: [],
        calibrations: [],
        canChooseTravelMode: false,
        canChooseOutputType: true,
        requiresDataPeriods: false,
        showDownloadWithCount: false,
        supportsCustomRanges: false,
        supportsNewDayTypesAndParts: false,
    },
    CS: {
        code: ANALYSIS_TYPES.CS.id,
        display: ANALYSIS_TYPES.CS.name,
        img: "/img/zone-types/cs.svg",
        featureName: "corridor_studies",
        isLightning: true,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES],
        timePeriods: [],
        addOns: [],
        calibrations: [],
        canChooseTravelMode: false,
        canChooseOutputType: true,
        requiresDataPeriods: false,
        showDownloadWithCount: false,
        supportsCustomRanges: false,
        supportsNewDayTypesAndParts: false,
    },
    // AADT project_type is not the same as the code, it is for example "Estimated_2018_AADT"
    AADT: {
        code: "aadt",
        display: "Average Annual Daily Traffic",
        urlPath: "aadt",
        img: "/img/zone-types/aadt.svg",
        isLightning: false,
        travelModes: [TRAVEL_MODES.ALL_VEHICLES, TRAVEL_MODES.ALL_VEHICLES_BY_WEIGHT],
        timePeriods: [TIME_PERIODS_TAB_SECTIONS.DATA_PERIODS],
        addOns: [ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS, ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS],
        calibrations: [CALIBRATIONS.AADT_OUTPUT],
        canChooseTravelMode: true,
        canChooseOutputType: false,
        requiresDataPeriods: false,
        showDownloadWithCount: false,
        supportsCustomRanges: false,
        supportsNewDayTypesAndParts: false,
        aadtTypes: {
            Estimated_2016_AADT: {
                code: ANALYSIS_TYPES.AADT2016.id,
                display: ANALYSIS_TYPES.AADT2016.name,
                featureName: "estimated_2016_aadt",
            },
            Estimated_2017_AADT: {
                code: ANALYSIS_TYPES.AADT2017.id,
                display: ANALYSIS_TYPES.AADT2017.name,
                featureName: "estimated_2017_aadt",
            },
            Estimated_2018_AADT: {
                code: ANALYSIS_TYPES.AADT2018.id,
                display: ANALYSIS_TYPES.AADT2018.name,
                featureName: "estimated_2018_aadt",
            },
            Estimated_2019_AADT: {
                code: ANALYSIS_TYPES.AADT2019.id,
                display: ANALYSIS_TYPES.AADT2019.name,
                featureName: "estimated_2019_aadt",
            },
            Estimated_2020_AADT: {
                code: ANALYSIS_TYPES.AADT2020.id,
                display: ANALYSIS_TYPES.AADT2020.name,
                featureName: "estimated_2020_aadt",
            },
            Estimated_2021_AADT: {
                code: ANALYSIS_TYPES.AADT2021.id,
                display: ANALYSIS_TYPES.AADT2021.name,
                featureName: "estimated_2021_aadt",
            },
            Estimated_2022_AADT: {
                code: ANALYSIS_TYPES.AADT2022.id,
                display: ANALYSIS_TYPES.AADT2022.name,
                featureName: "estimated_2022_aadt",
            },
            Estimated_2023_AADT: {
                code: ANALYSIS_TYPES.AADT2023.id,
                display: ANALYSIS_TYPES.AADT2023.name,
                featureName: "estimated_2023_aadt",
            },
        },
    },
};

export const CREATE_ANALYSIS_TYPES_LIST = Object.values(CREATE_ANALYSIS_TYPES);

export type TCreateAnalysisType = typeof CREATE_ANALYSIS_TYPES[keyof typeof CREATE_ANALYSIS_TYPES];

export const DEPRECATED_ANALYSIS_TYPES_LIST = [ANALYSIS_TYPES.VHWA];

export const ANALYSIS_TYPES_WITH_ONLY_OSM_SEGMENTS = [
    CREATE_ANALYSIS_TYPES.SEGMENT.code,
    CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.code,
    CREATE_ANALYSIS_TYPES.NETWORK_OD.code,
    CREATE_ANALYSIS_TYPES.CONGESTION.code,
    CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code,
    CREATE_ANALYSIS_TYPES.TMC.code,
    CREATE_ANALYSIS_TYPES.TT_TMC.code,
];

export const ALL_VEHICLES_TOMTOM_SUPPORTED_ANALYSES = [
    CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.code,
    CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.code,
    CREATE_ANALYSIS_TYPES.NETWORK_OD.code,
    CREATE_ANALYSIS_TYPES.TT_TMC.code,
];

export const US_ONLY_ANALYSIS_TYPES = [
    CREATE_ANALYSIS_TYPES.NETWORK_OD.code,
    CREATE_ANALYSIS_TYPES.TT_TMC.code,
];

export const ZONES_MODES = {
    ZONE_SETS: "ZONE_SETS",
    CHOOSE_ZONES: "CHOOSE_ZONES",
} as const;

export type TZoneModeKey = keyof typeof ZONES_MODES;
export type TZoneMode = typeof ZONES_MODES[keyof typeof ZONES_MODES];

export const ANALYSIS_CONSTANTS = {
    hwlZoneCountWarning: "Analysis cannot have more than 100 zones",
    noSelectedZonesWarning: "No zones are selected. Please select at least one zone",
    exceedZonesLimitWarning:
        "The analysis has exceeded the absolute limit of number of zones, please remove zones before starting processing",
    truckVolumePassThroughZonesWarning:
        "All zones should be pass-through for StreetLight Truck Volume",
    invalidZonesWarning: "Analysis cannot have invalid zones",
    zoneAreaQuotaWarning:
        "The zone sets used in this analysis are above the size limit allowed to be used in this analysis. Please edit the zone size and try again",
    presetGeographyWarning: (zoneMode: string) =>
        `The ${
            zoneMode === ZONES_MODES.ZONE_SETS ? "zone sets" : "zones"
        } used in this analysis don't match to selected Pre-Set Geography type`,
    zoneCountryWarning: (zoneMode: string) =>
        `The ${
            zoneMode === ZONES_MODES.ZONE_SETS ? "zone sets" : "zones"
        } used in this analysis don't match to selected Country`,
    bypassUpsamplingWarning: `Metric results for this analysis may include rows generated using an upsampling technique
        that uses data from adjacent day parts and analogous day parts outside of the original data period to synthesize
        a representative metric in cases where sample size would have been otherwise too low to provide a high-quality result. `,
    analysisTypeHelp: {
        options: [
            {
                title: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.name,
                shortTitle: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.name,
                description:
                    "Get traffic volumes, speeds, vehicle miles/km traveled (VMT/VKT), and congestion metrics from 2019 through the current year. Analyze data by time of day or day of week. Download up to 36 months of results in a single analysis for monthly time trends",
                code: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.id,
                analysesTypes: [
                    CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT,
                    CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT,
                ],
                imagePath: "/img/zone-types/np-analysis.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.isLightning,
                defaultAnalysisType: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.id,
                analysisTypesGroup: [
                    {
                        code: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.id,
                        featureName: CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.featureName,
                        dataYears: [],
                        CustomIcon: NPSegmentIcon,
                        display: "Segment Metrics",
                        description:
                            "Get metrics over the length of selected road segments, including volume, speed, VMT/VKT, and VHD.",
                        disabled: false,
                    },
                    {
                        code: ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.id,
                        featureName: CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.featureName,
                        dataYears: [],
                        CustomIcon: NPSpotIcon,
                        display: "Spot Metrics",
                        description:
                            "Get metrics at specified points along the road, including volume and spot speed.",
                        disabled: false,
                    },
                ],
                showHelpForGroup: true,
            },
            {
                title: ANALYSIS_TYPES.CS.name,
                shortTitle: ANALYSIS_TYPES.CS.name,
                description:
                    "Produce a detailed, interactive report of Volume, Congestion and Speed Progression for a specified corridor, and inform corridor decisions and priorities.",
                code: ANALYSIS_TYPES.CS.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.CS],
                imagePath: "/img/zone-types/cs.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.CS.isLightning,
            },
            {
                title: ANALYSIS_TYPES.NETWORK_OD.name,
                shortTitle: ANALYSIS_TYPES.NETWORK_OD.name,
                description:
                    "Recent segment-level origin-destination volumes to understand trip distribution through an intersection, corridor, or network. Supports segment-to-segment, pass-through origin-destination analyses.",
                code: ANALYSIS_TYPES.NETWORK_OD.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.NETWORK_OD],
                imagePath: CREATE_ANALYSIS_TYPES.NETWORK_OD.img,
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.NETWORK_OD.isLightning,
            },
            {
                title: ANALYSIS_TYPES.ATM.name,
                shortTitle: ANALYSIS_TYPES.ATM.name,
                description:
                    "Select areas to analyze bicycle and pedestrian volumes trends across space, time, and mode.",
                code: ANALYSIS_TYPES.ATM.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.ATM],
                imagePath: "/img/zone-types/at.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.ATM.isLightning,
            },
            {
                title: ANALYSIS_TYPES.RV.name,
                shortTitle: ANALYSIS_TYPES.RV.name,
                description:
                    "Generate AADT for all major roadways within a city, county, or region.",
                code: ANALYSIS_TYPES.RV.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.RV],
                imagePath: "/img/zone-types/rv.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.RV.isLightning,
            },
            {
                title: ANALYSIS_TYPES.CT.name,
                shortTitle: ANALYSIS_TYPES.CT.name,
                description:
                    "Produce a detailed, interactive report of volume, congestion, and reliability metrics for your specified region and network and inform congestion management and mobility decisions.",
                code: ANALYSIS_TYPES.CT.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.CT],
                imagePath: "/img/zone-types/ct.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.CT.isLightning,
            },
            {
                title: ANALYSIS_TYPES.TMC.name,
                shortTitle: "Turning Movement Counts",
                description:
                    "Analyze the movement of vehicles in an intersection to determine traffic that moves left, right, or continues straight.",
                code: ANALYSIS_TYPES.TMC.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.TMC, CREATE_ANALYSIS_TYPES.TT_TMC],
                imagePath: "/img/zone-types/tmc.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.TMC.isLightning,
                defaultAnalysisType: CREATE_ANALYSIS_TYPES.TT_TMC.code,
                analysisTypesGroup: [
                    {
                        code: CREATE_ANALYSIS_TYPES.TMC.code,
                        featureName: CREATE_ANALYSIS_TYPES.TMC.featureName,
                        dataYears: [2018, 2019, 2020, 2021, 2022],
                        display: "Historical Data",
                        description: "Analyze data from 2018 - 2023",
                        disabled: false,
                        CustomIcon: TMCHistoricDataIcon,
                    },
                    {
                        code: CREATE_ANALYSIS_TYPES.TT_TMC.code,
                        featureName: CREATE_ANALYSIS_TYPES.TT_TMC.featureName,
                        dataYears: [2022, 2023, 2024],
                        display: "Recent Data",
                        description: "Analyze the latest 22 months of data",
                        disabled: false,
                        CustomIcon: TMCRecentDataIcon,
                    },
                ],
            },
            {
                title: ANALYSIS_TYPES.ZA.name,
                shortTitle: ANALYSIS_TYPES.ZA.name,
                description:
                    "Use this to analyze traffic starting in, stopping in, or passing-through one group of locations.",
                code: ANALYSIS_TYPES.ZA.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.ZA],
                imagePath: "/img/zone-types/za.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.ZA.isLightning,
            },
            {
                title: ANALYSIS_TYPES.OD.name,
                shortTitle: ANALYSIS_TYPES.OD.name,
                description:
                    "Use this zone set configuration to analyze traffic that travels from one group of locations " +
                    "to another group of locations.",
                code: ANALYSIS_TYPES.OD.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.OD],
                imagePath: "/img/zone-types/od.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.OD.isLightning,
            },
            {
                title: ANALYSIS_TYPES.SEGMENT.name,
                shortTitle: "Segment",
                description:
                    "Get trip information for a specific road segment from one pass-through gate to another.",
                code: ANALYSIS_TYPES.SEGMENT.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.SEGMENT],
                imagePath: "/img/zone-types/segment-analysis.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.SEGMENT.isLightning,
            },
            {
                title: "AADT",
                shortTitle: "AADT",
                description:
                    "Measure Average Annual Daily Traffic anywhere in the contiguous United States and Canada.",
                code: CREATE_ANALYSIS_TYPES.AADT.code,
                imagePath: "/img/zone-types/aadt.svg",
                // "features" is an array containing feature name arrays. At least one of the feature name
                // arrays must be all on to enable the option.
                features: [
                    ["blended", "estimated_2016_aadt", "personal_all_vehicle_travel"],
                    ["blended", "estimated_2017_aadt", "personal_all_vehicle_travel"],
                    ["blended", "estimated_2018_aadt", "personal_all_vehicle_travel"],
                    ["blended", "estimated_2019_aadt", "personal_all_vehicle_travel"],
                    ["blended", "estimated_2020_aadt", "personal_all_vehicle_travel"],
                    ["blended", "estimated_2021_aadt", "personal_all_vehicle_travel"],
                    ["blended", "estimated_2022_aadt", "personal_all_vehicle_travel"],
                    ["blended", "estimated_2023_aadt", "personal_all_vehicle_travel"],
                ],
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.AADT.isLightning,
            },
            {
                title: ANALYSIS_TYPES.TRZA.name,
                shortTitle: ANALYSIS_TYPES.TRZA.name,
                description:
                    "Use this configuration to see how traffic flows to and from an area or a road segment.",
                code: ANALYSIS_TYPES.TRZA.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.TOP_ROUTES_ZA],
                imagePath: "/img/zone-types/top-routes-za.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.TOP_ROUTES_ZA.isLightning,
            },
            {
                title: ANALYSIS_TYPES.ODMF.name,
                shortTitle: ANALYSIS_TYPES.ODMF.name,
                description:
                    "Look at trips that travel from one group of locations to another group of locations, but through a specific filter of locations.",
                code: ANALYSIS_TYPES.ODMF.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.ODMF],
                imagePath: "/img/zone-types/od-middle-filters.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.ODMF.isLightning,
            },
            {
                title: ANALYSIS_TYPES.ODG.name,
                shortTitle: ANALYSIS_TYPES.ODG.name,
                description:
                    "Look at specific trips that come and go from your selected zone to industry-standard areas",
                code: ANALYSIS_TYPES.ODG.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.ODG],
                imagePath: "/img/zone-types/od-geography.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.ODG.isLightning,
            },
            {
                title: ANALYSIS_TYPES.TROD.name,
                shortTitle: ANALYSIS_TYPES.TROD.name,
                description:
                    "See the most popular routes for trips that go between your selected origins and destinations.",
                code: ANALYSIS_TYPES.TROD.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.TOP_ROUTES_OD],
                imagePath: "/img/zone-types/top-routes-od.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.TOP_ROUTES_OD.isLightning,
            },
            {
                title: ANALYSIS_TYPES.K_FACTOR.name,
                shortTitle: "K-Factor and Seasonal Factors",
                description:
                    "Estimated K30 values for travel matching the direction settings of the zone.",
                code: ANALYSIS_TYPES.K_FACTOR.id,
                analysesTypes: [CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION],
                imagePath: "/img/zone-types/aadt.svg",
                isNew: false,
                isLightning: CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.isLightning,
            },
        ],
    },
    travelModeHelp: {
        title: "Select the mode of travel to analyze",
        options: [
            {
                title: ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_ONLY.display,
                description: "Use this to measure traffic with our most robust sample size.",
                code: ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_ONLY.code,
            },
            {
                title: ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_LBS.display,
                description: "Use this to measure traffic with our most robust sample size.",
                code: ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_LBS.code,
            },
            {
                title: ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_CVD.display,
                description: "Use this to measure traffic with our most robust sample size.",
                code: ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_CVD.code,
            },
            {
                title: TRAVEL_MODES.ALL_VEHICLES_BY_WEIGHT.display,
                description: "Use this to measure traffic with our most robust sample size.",
                code: TRAVEL_MODES.ALL_VEHICLES_BY_WEIGHT.code,
            },
            {
                title: TRAVEL_MODES.TRUCK.display,
                description: "Use this to understand the movement of commercial trucks.",
                code: TRAVEL_MODES.TRUCK.code,
            },
            {
                title: TRAVEL_MODES.BICYCLE.display,
                description:
                    "Use this to look at bicycle data derived using StreetLight's machine learning algorithms.",
                code: TRAVEL_MODES.BICYCLE.code,
            },
            {
                title: TRAVEL_MODES.PEDESTRIAN.display,
                description:
                    "Use this to look at pedestrian data derived using StreetLight's machine learning algorithms.",
                code: TRAVEL_MODES.PEDESTRIAN.code,
            },
            {
                title: TRAVEL_MODES.BUS.display,
                description:
                    "Use this to look at bus data derived using StreetLight's machine learning algorithms.",
                code: TRAVEL_MODES.BUS.code,
            },
            {
                title: TRAVEL_MODES.RAIL.display,
                description:
                    "Use this to look at rail data derived using StreetLight's machine learning algorithms.",
                code: TRAVEL_MODES.RAIL.code,
            },
        ],
    },
    MAX_PREM_A_BINS: 50,
    MAX_HWL_ZONES: 100,
};

//TODO: remove once all analysis types support bike/ped volume
export const BIKE_PED_VOLUME_SUPPORTED_ANALYSES = [
    CREATE_ANALYSIS_TYPES.OD.code,
    CREATE_ANALYSIS_TYPES.ODMF.code,
    CREATE_ANALYSIS_TYPES.ZA.code,
    CREATE_ANALYSIS_TYPES.ODG.code,
];

export const TABS = {
    BASICS: {
        id: "basics",
        name: "Basic Info",
    },
    TIME_PERIODS: {
        id: "timePeriods",
        name: "Time Periods",
    },
    ZONES: {
        id: "zones",
        name: "Zones",
    },
    ADD_ONS: {
        id: "addOns",
        name: "Metrics",
    },
};

export const VIEW_TABS = {
    ...TABS,
};

export const REVIEW_TABS = {
    ...TABS,
    REVIEW: {
        id: "review",
        name: "Review",
    },
};

export const TABS_LIST = Object.values(TABS);

export const VIEW_TABS_LIST = Object.values(VIEW_TABS);

export const REVIEW_TABS_LIST = Object.values(REVIEW_TABS);

export const SCREEN_MODES = {
    DEFAULT: {
        id: "DEFAULT",
        isReadOnly: false,
        tabsList: TABS_LIST,
    },
    COPY_ANALYSIS: {
        id: "COPY_ANALYSIS",
        isReadOnly: false,
        tabsList: TABS_LIST,
    },
    DRAFT: {
        id: "DRAFT",
        isReadOnly: false,
        tabsList: TABS_LIST,
    },
    VIEW: {
        id: "VIEW",
        isReadOnly: true,
        tabsList: VIEW_TABS_LIST,
    },
    REVIEW: {
        id: "REVIEW",
        isReadOnly: true,
        tabsList: REVIEW_TABS_LIST,
    },
};

export const SCREEN_MODES_LIST = Object.values(SCREEN_MODES);

export const ANALYSIS_SUCCESS_MSG = {
    confirmMessage: {
        title: "Analysis confirmed",
        body: "You will be able to visualize and download the results when the analysis has finished running.",
    },
    confirmDownloadOnlyMessage: {
        title: "Analysis confirmed",
        body: "You will be able to download the results when the analysis has finished running.",
    },
    confirmVisualizeOnlyMessage: {
        title: "Analysis confirmed",
        body: "You will be able to visualize the results when the analysis has finished running.",
    },
    draftMessage: {
        title: "Analysis saved",
        body: "You can come back to this draft analysis and work on it any time.",
    },
    emptyConfirmMessage: {
        title: "Analysis confirmed",
        body: null,
    },
};

export const FIFTEEN_MINUTES_BIN_GRANULARITY = 15;

export const HISTORICAL_DATA_YEARS = [
    2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024,
];

export const AVAILABLE_ALL_VEHICLES_BY_WEIGHT_YEARS = [2019, 2020, 2021];
export const AVAILABLE_AADT_YEARS = [2017, 2018, 2019, 2020, 2021, 2022, 2023];
export const AVAILABLE_AADT_YEARS_FOR_AGPS = [2019, 2020, 2021, 2022];
export const DEFAULT_AADT_YEAR = 2022;
export const DEFAULT_AADT_YEAR_CA = 2020;
export const DEFAULT_ALL_VEHICLES_BY_WEIGHT_YEAR = 2021;

export const AVAILABLE_AADT_YEARS_BY_TRAVEL_MODE_CODE = {
    [MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code]: AVAILABLE_AADT_YEARS,
    [MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code]: AVAILABLE_ALL_VEHICLES_BY_WEIGHT_YEARS,
};

export const ANALYSIS_SCREEN_BREAKPOINTS_CONFIG = {
    "(max-width: 1280px)": {
        REGULAR: 600,
        ZONE_LIBRARY: 450,
        TMC_ANALYSIS: 640,
    },
    "(min-width: 1281px) and (max-width: 1480px)": {
        REGULAR: 600,
        ZONE_LIBRARY: 600,
        TMC_ANALYSIS: 640,
    },
    "(min-width: 1481px) and (max-width: 1800px)": {
        REGULAR: 740,
        ZONE_LIBRARY: 640,
        TMC_ANALYSIS: 640,
    },
    "(min-width: 1801px)": {
        REGULAR: 900,
        ZONE_LIBRARY: 800,
        TMC_ANALYSIS: 640,
    },
};
